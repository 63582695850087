import React, { useState, useEffect } from 'react';
import { Layout, Badge, Dropdown, Menu, message, Modal, Button, List } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './Header.css';

const { Header } = Layout;

const AppHeader = () => {
  const logoSrc = require('../assets/logo.png');
  const location = useLocation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false); // Состояние для управления видимостью модального окна
  const [allReadNotifications, setAllReadNotifications] = useState([]); // Для хранения всех прочитанных уведомлений
  const [menuVisible, setMenuVisible] = useState(false); // Состояние для отслеживания видимости меню

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) return;
  
    const db = getFirestore();
    const notificationsRef = collection(db, `users/${user.uid}/notifications`);
  
    // Подписка на обновления в коллекции notifications
    const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
      if (!snapshot.empty) {
        const fetchedNotifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date(), // Проверяем наличие createdAt
        }));
  
        // Сортируем уведомления по дате добавления (самые новые сверху)
        fetchedNotifications.sort((a, b) => b.createdAt - a.createdAt);
  
        setNotifications(fetchedNotifications);
        setUnreadCount(fetchedNotifications.filter(notification => !notification.isRead).length);
      } else {
        setNotifications([]); // Если уведомлений нет, сбрасываем массив
        setUnreadCount(0);
      }
    }, (error) => {
      message.error("Błąd podczas ładowania powiadomień.");
      console.error("Error fetching notifications: ", error);
    });
  
    // Очистка подписки при размонтировании компонента
    return () => unsubscribe();
  }, []);
  

  const handleLogoClick = () => {
    navigate('/'); // Перенаправляем на главную страницу
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/rozliczenia':
        return 'Rozliczenia';
      case '/faktury':
        return 'Faktury';
      case '/samochod':
        return 'Samochod';
      case '/konto':
        return 'Konto';
      case '/':
        return 'Strona główna';
      default:
        return 'Panel Spario';
    }
  };

  const handleMenuVisibleChange = (visible) => {
    setMenuVisible(visible);
    if (!visible) {
      handleMarkAsRead(); // Отмечаем уведомления как прочитанные при закрытии меню
    }
  };

  const handleMarkAsRead = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) return;

    const db = getFirestore();
    const unreadNotifications = notifications.filter(notification => !notification.isRead);

    try {
      const updatePromises = unreadNotifications.map(notification =>
        updateDoc(doc(db, `users/${user.uid}/notifications`, notification.id), {
          isRead: true,
        })
      );

      await Promise.all(updatePromises);

      const updatedNotifications = notifications.map(notification =>
        notification.isRead ? notification : { ...notification, isRead: true }
      );
      setNotifications(updatedNotifications);
      setUnreadCount(0);
    } catch (error) {
      message.error("Błąd podczas aktualizacji powiadomień.");
      console.error("Error updating notifications: ", error);
    }
  };

  const showModal = () => {
    setAllReadNotifications(notifications.filter(notification => notification.isRead));
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) {
      return `Dzisiaj o ${date.toLocaleTimeString('pl-PL', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    } else if (isYesterday) {
      return `Wczoraj o ${date.toLocaleTimeString('pl-PL', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    } else {
      return date.toLocaleString('pl-PL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  const notificationMenu = (
    <Menu > 
      {notifications.length > 0 ? (
        <>
          <Menu.ItemGroup title="Nie przeczytano">
            {notifications.filter(notification => !notification.isRead).length > 0 ? (
              notifications.filter(notification => !notification.isRead).map(notification => (
                <Menu.Item key={notification.id} className="notification-item">
                  <div className="notification-title">{notification.title || 'Bez tytułu'}</div>
                  <div className="notification-text">{notification.text || 'Bez treści'}</div>
                  <div className="notification-date">{formatDate(notification.createdAt)}</div>
                </Menu.Item>
              ))
            ) : (
              <Menu.Item className="notification-item">Brak nieprzeczytanych powiadomień</Menu.Item>
            )}
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup title="Przeczytano">
            {notifications.filter(notification => notification.isRead).slice(0, 5).map(notification => (
              <Menu.Item key={notification.id} className="notification-item">
                <div className="notification-title">{notification.title || 'Bez tytułu'}</div>
                <div className="notification-text">{notification.text || 'Bez treści'}</div>
                <div className="notification-date">{formatDate(notification.createdAt)}</div>
              </Menu.Item>
            ))}
            {notifications.filter(notification => notification.isRead).length > 5 && (
              <Menu.Item className="notification-item" onClick={showModal}>
                <Button type="link">Pokaż wszystkie przeczytane</Button>
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </>
      ) : (
        <Menu.Item>Brak nowych powiadomień</Menu.Item>
      )}
    </Menu>
  );

  return (
    <Header className="app-header" style={{ top: '10px'}}>
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src={logoSrc} alt="Logo" />
      </div>
      <div className="page-title">
        <span>{getPageTitle()}</span>
      </div>
      <div className="notification-icon">
        <Dropdown
          overlay={notificationMenu}
          trigger={['click']}
          visible={menuVisible}
          onVisibleChange={handleMenuVisibleChange}
        >
          <Badge
            count={unreadCount}
            style={{ boxShadow: 'none', color: '#fff' }} // Убираем ободок и задаем цвет фона и текста
          >
            <BellOutlined style={{ fontSize: '24px', color: 'black', cursor: 'pointer' }} />
          </Badge>
        </Dropdown>
      </div>

      {/* Модальное окно для всех прочитанных уведомлений */}
      <Modal
        title="Wszystkie przeczytane powiadomienia"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Zamknij
          </Button>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={allReadNotifications}
          renderItem={notification => (
            <List.Item key={notification.id}>
              <List.Item.Meta
                title={notification.title || 'Bez tytułu'}
                description={
                  <>
                    <div>{notification.text || 'Bez treści'}</div>
                    <div className="notification-date">{formatDate(notification.createdAt)}</div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </Header>
  );
};

export default AppHeader;
