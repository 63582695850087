import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Конфигурация Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDVz0ho0nQb7Sh9o_0wFLZfVJM7aeGYgb0",
    authDomain: "ccmcolorpartner.firebaseapp.com",
    databaseURL: "https://ccmcolorpartner-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ccmcolorpartner",
    storageBucket: "ccmcolorpartner.appspot.com",
    messagingSenderId: "417966588632",
    appId: "1:417966588632:web:bf85b1ff7307bb86de8dad",
    measurementId: "G-WQP6RE6CQ3"
};

// Инициализация приложения Firebase
const app = initializeApp(firebaseConfig);

// Экспорт сервисов Firebase
export const auth = getAuth(app); // Аутентификация Firebase
export const db = getFirestore(app); // Firestore для работы с базой данных
export const realtimeDb = getDatabase(app); // Реальная база данных (Realtime Database)
export const storage = getStorage(app); // Хранилище для файлов
export const functions = getFunctions(app); // Облачные функции (Cloud Functions)
export const messaging = getMessaging(app); // Обработка сообщений (FCM)

// Регистрация Service Worker и получение токена FCM
export const requestForToken = async () => {
    try {
      // Регистрируем Service Worker для обработки push-уведомлений
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

      // Получаем токен FCM с зарегистрированным Service Worker
      const currentToken = await getToken(messaging, { 
        vapidKey: 'BJroAxoAUPWhibFpcwprGtl9JZbdzFZjjPZkwfg2rgDgCoL5BuJedX97B2OYxQ_PsDI6OjO3QUlR9yWQQIgmlh0', 
        serviceWorkerRegistration: registration 
      });

      if (currentToken) {
        console.log('Current token:', currentToken);
        return currentToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
        return null;
      }
    } catch (error) {
      console.error('An error occurred while retrieving token: ', error);
      return null;
    }
};

// Функция для прослушивания входящих сообщений FCM
export const onMessageListener = () => {
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log('Message received: ', payload);
        resolve(payload);
      });
    });
};
