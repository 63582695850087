import React, { useState } from 'react';
import { Upload, message, Progress, Button, Divider, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import Lottie from 'lottie-react';
import loadingAnimation from './lottie/isProcessing.json';
import errorAnimation from './lottie/error.json';
import CameraCapture from './CameraCapture';
import { v4 as uuidv4 } from 'uuid';

const { Dragger } = Upload;

const InvoiceProcessor = ({ onProcessComplete }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [messageContent, setMessageContent] = useState('');

    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
    const GOOGLE_VISION_API_KEY = process.env.REACT_APP_GOOGLE_VISION_API_KEY;

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const classifyAndExtractData = async (ocrText) => {
        const messages = [
            {
                role: "system",
                content: "Jesteś pomocnym asystentem."
            },
            {
                role: "user",
                content:
                    `Mam tekst faktury. Proszę wyodrębnić następujące pola i podać wartości:
                    - fuelType: rodzaj paliwa
                    - grossAmount: kwota brutto
                    - isBuyerAddressGrabiszynska: adres nabywcy zawiera "GRABISZYŃSKA" (true/false)
                    - isBuyerColorPartner: nabywca "COLOR PARTNER" (true/false)
                    - isImageReadable: obraz jest czytelny (true/false)
                    - isOnlyFuel: czy zakup dotyczy tylko paliwa (true/false)
                    - liters: liczba litrów
                    - netAmount: kwota netto
                    - nipseller: NIP sprzedawcy
                    - numerfaktury: numer faktury
                    - purchaseDate: data zakupu
                    - registrationNumber: numer rejestracyjny
                    - type: typ dokumentu (np. "faktura")
                    - vatAmount: kwota VAT
                    - vatRate: stawka VAT
            
                    Tekst faktury:
                    ${ocrText}
            
                    Format odpowiedzi:
                    {
                        "fuelType": "wartość",
                        "grossAmount": "wartość",
                        "isBuyerAddressGrabiszynska": true/false,
                        "isBuyerColorPartner": true/false,
                        "isImageReadable": true/false,
                        "isOnlyFuel": true/false,
                        "liters": "wartość",
                        "netAmount": "wartość",
                        "nipseller": "wartość",
                        "numerfaktury": "wartość",
                        "purchaseDate": "YYYY-MM-DD",
                        "registrationNumber": "wartość",
                        "type": "wartość",
                        "vatAmount": "wartość",
                        "vatRate": "wartość"
                    }`
            }
        ];

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4',
                    messages: messages,
                    max_tokens: 300,
                    temperature: 0,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                    },
                }
            );

            const responseText = response.data.choices[0].message.content.trim();
            const jsonMatch = responseText.match(/{[\s\S]*}/);

            if (jsonMatch) {
                const extractedData = JSON.parse(jsonMatch[0]);
                return extractedData;
            } else {
                throw new Error("Nie znaleziono JSON w odpowiedzi");
            }
        } catch (error) {
            console.error('Błąd podczas wyodrębniania danych z OpenAI:', error);
            return null;
        }
    };

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('Błąd podczas kompresji obrazu:', error);
            return file;
        }
    };

    const handleFileUpload = async (files) => {
        setLoading(true);
        setIsProcessing(true);
        setIsUploading(true);
        setHasError(false);
        setMessageContent('');
        setUploadProgress(0);
        let successfullyProcessed = 0;

        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            if (file.size > 10 * 1024 * 1024) {
                setMessageContent(`Kompresowanie obrazu ${file.name}...`);
                try {
                    file = await compressImage(file);
                } catch (error) {
                    setMessageContent(`Nie udało się skompresować obrazu ${file.name}. Przesyłamy oryginał.`);
                }
            }

            // Генерируем уникальное имя файла сразу после компрессии
            const uniqueFilename = `invoices/${uuidv4()}.jpg`;
            // Создаем новый файл с уникальным именем
            const uniqueFile = new File([file], uniqueFilename, { type: file.type });

            setMessageContent(`Przesyłanie ${uniqueFilename}...`);

            const storageRef = ref(storage, uniqueFilename);
            const uploadTask = uploadBytesResumable(storageRef, uniqueFile);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    setMessageContent(`Przesyłanie ${uniqueFilename}: ${Math.round(progress)}%`);
                },
                (error) => {
                    setMessageContent(`Błąd przesyłania ${uniqueFilename}: ${error.message}`);
                    setIsProcessing(false);
                    setIsUploading(false);
                    setHasError(true);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        setMessageContent(`Przetwarzanie OCR dla ${uniqueFilename}...`);

                        const requestPayload = {
                            requests: [
                                {
                                    image: {
                                        source: {
                                            imageUri: downloadURL
                                        }
                                    },
                                    features: [
                                        {
                                            type: 'TEXT_DETECTION'
                                        }
                                    ]
                                }
                            ]
                        };

                        const response = await axios.post(
                            `https://vision.googleapis.com/v1/images:annotate?key=${GOOGLE_VISION_API_KEY}`,
                            requestPayload
                        );

                        if (response.data && response.data.responses && response.data.responses[0]) {
                            const text = response.data.responses[0]?.textAnnotations[0]?.description || 'Tekst nie znaleziony';
                            const extractedData = await classifyAndExtractData(text);
                            if (extractedData) {
                                extractedData.fileURL = downloadURL;
                                onProcessComplete(extractedData);

                                successfullyProcessed++;
                                setMessageContent(`Faktura ${extractedData.numerfaktury} została pomyślnie dodana!`);

                                if (successfullyProcessed === files.length) {
                                    setMessageContent(`Wszystkie ${files.length} faktury zostały pomyślnie przetworzone i dodane do listy!`);
                                }
                            } else {
                                setMessageContent(`Nie udało się wyodrębnić danych z tekstu dla ${uniqueFilename}`);
                                setIsProcessing(false);
                                setIsUploading(false);
                                setHasError(true);
                            }
                        } else {
                            throw new Error('Odpowiedź z Google Vision API nie zawiera danych');
                        }
                    } catch (error) {
                        setMessageContent(`Błąd rozpoznawania tekstu dla ${uniqueFilename}`);
                        setIsProcessing(false);
                        setIsUploading(false);
                        setHasError(true);
                    }
                }
            );
        }

        setLoading(false);
        setIsUploading(false);
    };


    const startScanning = () => {
        setIsModalVisible(true);
        setIsScanning(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setIsScanning(false);
        setCapturedImage(null);
    };

    const retry = () => {
        setHasError(false);
        setIsProcessing(false);
        setIsUploading(false);
        setMessageContent('');
    };

    const retakeScan = () => {
        setCapturedImage(null);
        setIsScanning(true);
    };

    const confirmScan = () => {
        // Генерация уникального идентификатора
        const uniqueId = uuidv4();
        // Создание уникального имени файла
        const uniqueFilename = `captured_image_${uniqueId}.jpg`;
        const file = dataURLtoFile(capturedImage, uniqueFilename);
        handleFileUpload([file]);
        setIsScanning(false);
        setIsModalVisible(false);
    };

    const draggerProps = {
        name: 'file',
        multiple: true,
        beforeUpload: (file, fileList) => {
            handleFileUpload(fileList);
            return false;
        },
        onDrop(e) {
            console.log('Upuszczone pliki', e.dataTransfer.files);
        },
    };

    return (
        <div>
            {!isProcessing && !isUploading && !hasError && (
                <>
                    <Dragger {...draggerProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Kliknij lub przeciągnij plik, aby go przesłać</p>
                        <p className="ant-upload-hint">
                            Obsługa wielu plików. Surowo zabroniona jest przesyłka danych poufnych lub innych zabronionych plików.
                        </p>
                        {loading && <Progress percent={uploadProgress} />}
                    </Dragger>
                    <Divider orientation="left">Kryteria weryfikacji faktury</Divider>
                    <div>
                        <ul>
                            <li>Faktura musi zawierać poprawny numer rejestracyjny</li>
                            <li>Musi być podana data zakupu.</li>
                            <li>Adres nabywcy powinien zawierać COLOR PARTNER SP. Z O.O., UL. GRABISZYŃSKA 214/7, 53-235 WROCŁAW, NIP 8943198563 lub być prawidłowo wskazany</li>
                            <li>Faktura musi być czytelna.</li>
                            <li>Dane dotyczące liczby litrów, rodzaju paliwa i ceny muszą być podane.</li>
                            <li>Poprawne wskazanie kwoty VAT i stawki VAT.</li>
                            <li>Faktury można dodawać za dany tydzień do poniedziałku do godziny 8:00</li>
                        </ul>
                        <p>Faktury, które nie spełniają podanych kryteriów, zostaną odrzucone.</p>
                    </div>

                </>
            )}

            {(isProcessing || isUploading || hasError) && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Lottie
                        animationData={hasError ? errorAnimation : loadingAnimation}
                        loop={!hasError}
                        style={{ width: 300, height: 300 }}
                    />
                    <p style={{ textAlign: 'center', marginTop: 20 }}>{messageContent}</p>
                    {hasError && (
                        <Button type="primary" onClick={retry} style={{ marginTop: 20 }}>
                            Spróbuj ponownie
                        </Button>
                    )}
                </div>
            )}

            <Modal
                title="Skanowanie dokumentu"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                width={600}
                style={{ top: 20 }}
                styles={{
                    body: { padding: 0, margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }
                }}
                centered
            >
                {isProcessing ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <Lottie animationData={loadingAnimation} loop={true} style={{ width: '70%', height: '70%' }} />
                    </div>
                ) : capturedImage ? (
                    <div style={{ textAlign: 'center', padding: 20 }}>
                        <img
                            src={capturedImage}
                            alt="Zeskanowany dokument"
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: 'calc(100% - 100px)',
                                objectFit: 'contain',
                                marginBottom: 0,
                            }}
                        />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                            <Button
                                type="default"
                                onClick={retakeScan}
                                style={{ width: '48%' }}
                            >
                                Skanuj ponownie
                            </Button>
                            <Button
                                type="primary"
                                onClick={confirmScan}
                                style={{ width: '48%' }}
                            >
                                Dalej
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        padding: 20,
                        boxSizing: 'border-box',

                    }}>
                        <CameraCapture
                            isScanning={isScanning}
                            onCapture={(imageSrc) => setCapturedImage(imageSrc)}
                            style={{ width: '100%', height: '100%', borderRadius: '15px' }}
                        />
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default InvoiceProcessor;
