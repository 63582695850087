import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig'; // Импорт Firestore и Auth из конфигурации
import { collection, doc, getDoc } from 'firebase/firestore';
import { Modal, Button } from 'antd';
import 'antd/dist/reset.css'; // Импорт стилей Ant Design

const Samochod = () => {
  const [carData, setCarData] = useState(null);
  const [loading, setLoading] = useState(true); // Stan ładowania
  const [error, setError] = useState(null); // Stan błędu
  const [visible, setVisible] = useState(false); // Widoczność okna modalnego
  const [selectedImage, setSelectedImage] = useState(null); // Wybrane zdjęcie do podglądu

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const userId = auth.currentUser?.uid; // Pobierz aktualnego użytkownika
        if (userId) {
          // Ścieżka do dokumentu o samochodzie
          const docRef = doc(collection(db, `users/${userId}/dokumenty`), 'samochod');

          // Pobierz dokument o samochodzie
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.carOwnership === 'Własny') {
              setCarData(data);
            } else {
              setCarData(null);
            }
          } else {
            setError("Brak danych o samochodzie.");
          }
        } else {
          setError("Użytkownik nie jest uwierzytelniony.");
        }
      } catch (error) {
        setError("Błąd podczas pobierania danych o samochodzie: " + error.message);
      } finally {
        setLoading(false); // Ustaw stan ładowania na false
      }
    };

    fetchCarData();
  }, []);

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedImage(null);
  };

  if (loading) {
    return <p>Ładowanie danych o samochodzie...</p>; // Wskaźnik ładowania
  }

  if (error) {
    return <p>{error}</p>; // Komunikat o błędzie
  }

  return (
    <div style={{ padding: '20px', border: '1px solid #d9d9d9', borderRadius: '12px' }}>
      {carData ? (
        <div>
          <h2 style={{ paddingBottom: '10px'}}>Informacje o samochodzie</h2>
          <div style={{ marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',paddingTop: '10px', marginBottom: '10px' }}>
              <p><strong>Marka:</strong> {carData.carMake || 'Brak danych'}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',paddingTop: '10px', marginBottom: '10px' }}>
              <p><strong>Model:</strong> {carData.carModel || 'Brak danych'}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px', paddingTop: '10px',marginBottom: '10px' }}>
              <p><strong>Własność:</strong> {carData.carOwnership || 'Brak danych'}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px', paddingTop: '10px',marginBottom: '10px' }}>
              <p><strong>Numer rejestracyjny:</strong> {carData.carRegistrationNumber || 'Brak danych'}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',paddingTop: '10px', marginBottom: '10px' }}>
              <p><strong>VIN:</strong> {carData.carVin || 'Brak danych'}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',paddingTop: '10px', marginBottom: '10px' }}>
              <p><strong>Rok:</strong> {carData.carYear || 'Brak danych'}</p>
            </div>
          </div>

          {carData.dowodPrzod && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',paddingTop: '10px', marginBottom: '10px' }}>
              <p><strong>Dowód Przód:</strong></p>
              <Button onClick={() => handleOpenModal(carData.dowodPrzod)}>Zobacz obraz</Button>
            </div>
          )}
          {carData.dowodTyl && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',paddingTop: '10px', marginBottom: '10px' }}>
              <p><strong>Dowód Tył:</strong></p>
              <Button onClick={() => handleOpenModal(carData.dowodTyl)}>Zobacz obraz</Button>
            </div>
          )}
          {carData.ubezpieczenie && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px', marginBottom: '10px' }}>
              <p><strong>Ubezpieczenie:</strong></p>
              <Button onClick={() => handleOpenModal(carData.ubezpieczenie)}>Zobacz obraz</Button>
            </div>
          )}

          {selectedImage && (
            <Modal
              visible={visible}
              footer={[
                <Button key="cancel" onClick={handleCancel}>
                  Zamknij
                </Button>,
              ]}
              onCancel={handleCancel}
              width="80%"
            >
              <img
                src={selectedImage}
                alt="Podgląd dokumentu"
                style={{ width: '100%', height: 'auto' }}
              />
            </Modal>
          )}
        </div>
      ) : (
        <p>Kierowca wybrał samochód partnera. Brak dostępnych informacji.</p>
      )}
    </div>
  );
};

export default Samochod;
