import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, message, Typography, Card } from 'antd';
import Lottie from 'lottie-react';
import successAnimation from '../components/lottie/success.json';
import { useAuth } from './AuthContext';
import { db, storage } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, doc, updateDoc, Timestamp } from 'firebase/firestore';

const DocumentSigning = ({ visible, onClose }) => {
    const { currentUser } = useAuth();
    const [documents, setDocuments] = useState([]);
    const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
    const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [allDocumentsSigned, setAllDocumentsSigned] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false); // Новое состояние для модального окна с успехом
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);
    const [currentContractId, setCurrentContractId] = useState(null);
    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const [emptySignatureData, setEmptySignatureData] = useState('');

    useEffect(() => {
        const fetchDocuments = async () => {
            if (currentUser) {
                const docsSnapshot = await getDocs(collection(db, `users/${currentUser.uid}/umowy`));
                const docs = docsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setDocuments(docs);

                const allSigned = docs.every(doc => doc.signed);
                setAllDocumentsSigned(allSigned);
            }
        };

        fetchDocuments();
    }, [currentUser]);

    useEffect(() => {
        const handleOrientationChange = (e) => {
            setIsPortrait(e.matches);
            if (!e.matches) {
                setTimeout(initializeCanvas, 100);
            }
        };

        const portraitMediaQuery = window.matchMedia("(orientation: portrait)");
        portraitMediaQuery.addEventListener('change', handleOrientationChange);

        return () => {
            portraitMediaQuery.removeEventListener('change', handleOrientationChange);
        };
    }, []);

    const initializeCanvas = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.strokeStyle = "black";
                ctx.lineWidth = 2;
                ctx.lineCap = "round";
                ctxRef.current = ctx;
                setEmptySignatureData(canvas.toDataURL());
            }
        }
    };

    const getCanvasCoordinates = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        if (event.touches && event.touches.length > 0) {
            return {
                x: (event.touches[0].clientX - rect.left) * scaleX,
                y: (event.touches[0].clientY - rect.top) * scaleY,
            };
        } else {
            return {
                x: (event.clientX - rect.left) * scaleX,
                y: (event.clientY - rect.top) * scaleY,
            };
        }
    };

    const startDrawing = (e) => {
        e.preventDefault(); 
        e.stopPropagation(); // Останавливаем распространение события
        document.body.classList.add('disable-scroll'); // Отключаем прокрутку страницы
    
        const ctx = ctxRef.current;
        if (ctx) {
            setDrawing(true);
            const { x, y } = getCanvasCoordinates(e);
            ctx.beginPath();
            ctx.moveTo(x, y);
        }
    };
    
    
    const draw = (e) => {
        e.preventDefault(); 
        e.stopPropagation(); // Останавливаем распространение события
    
        if (!drawing) return;
        const ctx = ctxRef.current;
        if (ctx) {
            const { x, y } = getCanvasCoordinates(e);
            ctx.lineTo(x, y);
            ctx.stroke();
        }
    };
    

    const stopDrawing = () => {
        setDrawing(false);
        document.body.classList.remove('disable-scroll'); // Включаем прокрутку страницы
    };
    

    const clearSignature = () => {
        const ctx = ctxRef.current;
        if (ctx) {
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
    };

    const saveSignature = async () => {
        if (!canvasRef.current) return;
        const signatureData = canvasRef.current.toDataURL();
        if (signatureData === emptySignatureData) {
            message.error("No signature detected. Please sign before saving.");
            return;
        }

        const byteCharacters = atob(signatureData.split(',')[1]);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = Array.from(slice, char => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: "image/png" });
        const signatureRef = ref(storage, `signatures/${currentUser.uid}/${currentContractId}.png`);

        try {
            await uploadBytesResumable(signatureRef, blob);
            const signatureURL = await getDownloadURL(signatureRef);
            const signedAt = Timestamp.now();

            await updateDoc(doc(db, `users/${currentUser.uid}/umowy`, currentContractId), {
                signature: signatureURL,
                signed: true,
                signedAt: signedAt,
            });

            message.success("Document successfully signed.");
            clearSignature();
            setIsSignatureModalVisible(false);

            const docsSnapshot = await getDocs(collection(db, `users/${currentUser.uid}/umowy`));
            const docs = docsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDocuments(docs);

            const allSigned = docs.every(doc => doc.signed);
            setAllDocumentsSigned(allSigned);

            if (allSigned) {
                setIsSuccessModalVisible(true); // Показать модальное окно с успехом
            }

        } catch (error) {
            message.error("Error saving signature. Please try again.");
            console.error("Error saving signature:", error);
        }
    };

    const openSignatureModal = (docId) => {
        setCurrentContractId(docId);
        clearSignature();
        setIsSignatureModalVisible(true);
        setTimeout(initializeCanvas, 100);
    };

    const openDocumentModal = (doc) => {
        setSelectedDocument(doc);
        setIsDocumentModalVisible(true);
    };

    const closeDocumentModal = () => {
        setIsDocumentModalVisible(false);
        setSelectedDocument(null);
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onClose}
                footer={null}
                maskClosable={false}
            >
                <div id="documents-container">
                    {documents.filter(doc => !doc.signed).map(doc => (
                        <Card
                            key={doc.id}
                            title={`Dokument: ${doc.id}`}
                            extra={<Button onClick={() => openDocumentModal(doc)}>Zobacz dokument</Button>}
                            style={{ marginBottom: '20px' }}
                        >
                            <p><span className="detail-label">Wygasa:</span> {new Date(doc.dataGeneracji.seconds * 1000).toLocaleString()}</p>
                            <p><span className="detail-label">Status:</span> "Niepodpisany"</p>
                            <Button type="primary" onClick={() => openSignatureModal(doc.id)} style={{ marginTop: '10px' }}>
                                Podpisz dokument
                            </Button>
                        </Card>
                    ))}
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <Button
                        type="primary"
                        onClick={onClose}
                        disabled={!allDocumentsSigned}
                    >
                        Zakończ
                    </Button>
                </div>
            </Modal>

            <Modal
                visible={isSignatureModalVisible}
                onCancel={() => setIsSignatureModalVisible(false)}
                footer={null}
                maskClosable={false}
            >
                {isPortrait ? (
                    <div style={{ padding: '20px', textAlign: 'center' }}>
                        <Typography.Title level={4}>Proszę obrócić urządzenie в poziome położenie, aby podpisać dokument.</Typography.Title>
                    </div>
                ) : (
                    <>
                        <Typography.Title level={4} style={{ textAlign: 'center' }}>
                            Podpisywanie dokumentu: {currentContractId}
                        </Typography.Title>
                        <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px', marginTop: '20px', overflow: 'hidden' }}>
                        <canvas
    ref={canvasRef}
    style={{ 
        width: '100%', 
        height: '200px', 
        backgroundColor: '#f9f9f9', 
        cursor: 'crosshair', 
        overflow: 'hidden', 
        touchAction: 'none' // предотвращаем нежелательные действия на мобильных устройствах
    }}
    onMouseDown={startDrawing}
    onMouseMove={draw}
    onMouseUp={stopDrawing}
    onTouchStart={startDrawing}
    onTouchMove={draw}
    onTouchEnd={stopDrawing}
/>

                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button onClick={clearSignature} style={{ marginRight: '10px' }}>
                                Wyczyść podpis
                            </Button>
                            <Button type="primary" onClick={saveSignature} disabled={!currentContractId}>
                                Zapisz podpis
                            </Button>
                        </div>
                    </>
                )}
            </Modal>

            <Modal
                visible={isDocumentModalVisible}
                onCancel={closeDocumentModal}
                footer={[
                    <Button key="close" onClick={closeDocumentModal}>Zamknij</Button>,
                    <Button key="sign" type="primary" onClick={() => openSignatureModal(selectedDocument.id)}>Podpisz</Button>
                ]}
            >
                {selectedDocument && (
                    <div>
                        <Typography.Title level={4}>Dokument: {selectedDocument.id}</Typography.Title>
                        <p><span className="detail-label">Wygasa:</span> {new Date(selectedDocument.dataGeneracji.seconds * 1000).toLocaleString()}</p>
                        <p><span className="detail-label">Status:</span> "Niepodpisany"</p>
                        <iframe
                            src={selectedDocument.pdfUrl}
                            title="Dokument PDF"
                            style={{ width: '100%', height: '500px', border: 'none' }}
                        />
                    </div>
                )}
            </Modal>

            <Modal
                visible={isSuccessModalVisible} // Модальное окно успеха
                onCancel={onClose}
                footer={null}
                maskClosable={false}
            >
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Lottie animationData={successAnimation} loop={false} autoplay style={{ width: '200px', height: '200px', margin: '0 auto' }} />
                    <Typography.Title level={4} style={{ marginTop: '20px' }}>
                        Wszystkie dokumenty zostały pomyślnie podpisane! Możesz teraz korzystać z aplikacji.
                    </Typography.Title>
                    <Button type="primary" onClick={onClose} style={{ marginTop: '20px' }}>
                        OK
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default DocumentSigning;
