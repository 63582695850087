import React, { useState, useEffect, useRef } from 'react';
import { realtimeDb, auth } from '../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { ref, onValue, push, set, off } from 'firebase/database';
import { Modal, Button, Input, List, Divider, Badge, Upload } from 'antd';
import { UploadOutlined, CloseCircleOutlined, FileOutlined } from '@ant-design/icons';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Pomoc.css';

const Pomoc = () => {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState({});
    const [chatName, setChatName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCreateChatModalVisible, setIsCreateChatModalVisible] = useState(false);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState(null);

    const messagesEndRef = useRef(null);
    const firestore = getFirestore();
    const storage = getStorage();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUserInfo(userDocSnap.data());
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, [firestore]);

    useEffect(() => {
        if (user) {
            const chatUnreadCounts = {};

            const chatsRef = ref(realtimeDb, 'chats');
            onValue(chatsRef, (snapshot) => {
                let chats = [];
                snapshot.forEach((snap) => {
                    const chat = { id: snap.key, ...snap.val() };
                    if (chat.creatorId === user.uid) { // Фильтруем чаты по creatorId
                        chats.push(chat);
                    }
                });

                const chatPromises = chats.map(async (chat) => {
                    const messagesRef = ref(realtimeDb, `chats/${chat.id}/messages`);
                    const msgs = await new Promise((resolve) => {
                        onValue(messagesRef, (snapshot) => {
                            let msgs = [];
                            snapshot.forEach((snap) => {
                                msgs.push({ id: snap.key, ...snap.val() });
                            });
                            resolve(msgs);
                        });
                    });

                    const unreadCount = countUnreadMessagesInChat(msgs);
                    chatUnreadCounts[chat.id] = unreadCount;

                    setUnreadCounts((prevCounts) => ({
                        ...prevCounts,
                        [chat.id]: unreadCount,
                    }));

                    chat.lastMessage = chat.lastMessage || {};
                    chat.lastMessage.timestamp = chat.lastMessage.timestamp || '1970-01-01T00:00:00Z';
                    chat.unreadCount = unreadCount;
                    chat.messages = msgs;

                    return chat;
                });

                Promise.all(chatPromises).then((chats) => {
                    chats.sort((a, b) => {
                        if (b.unreadCount !== a.unreadCount) {
                            return b.unreadCount - a.unreadCount;
                        }
                        return new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp);
                    });

                    setChats(chats);
                });
            });
        }
    }, [user]);

    const countUnreadMessagesInChat = (messages) => {
        return messages.reduce((count, msg) => {
            if (!msg.isRead && msg.sender !== user.email) {
                count++;
            }
            return count;
        }, 0);
    };

    useEffect(() => {
        let messagesRef;
        if (selectedChat) {
            messagesRef = ref(realtimeDb, `chats/${selectedChat.id}/messages`);
            onValue(messagesRef, (snapshot) => {
                let msgs = [];
                snapshot.forEach((snap) => {
                    msgs.push({ id: snap.key, ...snap.val() });
                });
                setMessages(msgs);
    
                // Отметить как прочитанные только сообщения, отправленные не текущим пользователем
                msgs.forEach((msg) => {
                    if (!msg.isRead && msg.senderId !== user.uid) {
                        set(ref(realtimeDb, `chats/${selectedChat.id}/messages/${msg.id}/isRead`), true);
                        set(ref(realtimeDb, `chats/${selectedChat.id}/messages/${msg.id}/readAt`), new Date().toISOString());
                    }
                });
    
                scrollToBottom();
            });
        }
    
        return () => {
            if (messagesRef) {
                off(messagesRef);
            }
        };
    }, [selectedChat, user]);
    

    useEffect(() => {
        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages]);

    const scrollToBottom = () => {
        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    const createChat = () => {
        if (chatName.trim()) {
            const newChatRef = push(ref(realtimeDb, 'chats'));
            set(newChatRef, {
                chatName: chatName,
                creatorId: user.uid,
                creatorName: `${userInfo.firstName} ${userInfo.lastName}`,
                timestamp: new Date().toISOString(),
                status: 'open',
                lastMessage: {
                    text: '',
                    senderName: '',
                    timestamp: '',
                    senderRole: 'user', // Добавляем senderRole для последнего сообщения
                },
                admins: [],
            }).then(() => {
                setSelectedChat({ id: newChatRef.key, chatName, status: 'open' });
                setIsCreateChatModalVisible(false);
                setIsModalVisible(true);
                setTimeout(scrollToBottom, 0);
            });
            setChatName('');
        }
    };
    

    const openChat = (chat) => {
        setSelectedChat(chat);
        setIsModalVisible(true);
        setTimeout(scrollToBottom, 0);
    };

    const sendMessage = async () => {
        if ((!message.trim() && !selectedFile) || !selectedChat) return;
    
        let fileUrl = null;
        let fileType = null;
        if (selectedFile) {
            const fileRef = storageRef(storage, `chatFiles/${selectedChat.id}/${Date.now()}_${selectedFile.name}`);
            await uploadBytes(fileRef, selectedFile);
            fileUrl = await getDownloadURL(fileRef);
            fileType = selectedFile.type;
        }
    
        const senderDocRef = doc(firestore, 'users', user.uid);
        const senderDocSnap = await getDoc(senderDocRef);
        const senderName = senderDocSnap.exists()
            ? `${senderDocSnap.data().firstName} ${senderDocSnap.data().lastName}`
            : user.email;
    
        const msg = {
            text: message,
            fileUrl: fileUrl || null,
            fileType: fileType || null,
            sender: senderName, // Используем `firstName lastName`
            senderId: user.uid, // Добавляем `senderId`
            senderRole: 'user', // Добавляем senderRole как 'user'
            timestamp: new Date().toISOString(),
            isRead: false,
            readAt: null,
        };
    
        await push(ref(realtimeDb, `chats/${selectedChat.id}/messages`), msg);
    
        await set(ref(realtimeDb, `chats/${selectedChat.id}/lastMessage`), {
            text: message,
            fileUrl: fileUrl || null,
            fileType: fileType || null,
            senderName: senderName,
            senderRole: 'user', // Добавляем senderRole для последнего сообщения
            timestamp: msg.timestamp,
            isRead: false,
        });
    
        setMessage('');
        setSelectedFile(null);
        setPreviewImageUrl(null); // Очищаем URL предварительного просмотра после отправки
        setTimeout(scrollToBottom, 0);
    };
    

    const handleFileChange = (info) => {
        const file = info.file.originFileObj || info.file;
        if (file instanceof Blob) {
            setSelectedFile(file);
            if (file.type.startsWith('image/')) {
                const previewUrl = URL.createObjectURL(file);
                setPreviewImageUrl(previewUrl);
            } else {
                setPreviewImageUrl(null);
            }
        } else {
            console.error('The selected file is not a valid Blob or File object');
        }
    };

    const removeSelectedImage = () => {
        setSelectedFile(null);
        setPreviewImageUrl(null);
    };

    const openImageModal = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setIsImageModalVisible(true);
    };

    const closeImageModal = () => {
        setIsImageModalVisible(false);
        setModalImageUrl(null);
    };

    const extractFileName = (url) => {
        try {
            const fullPath = decodeURIComponent(url.split('?')[0]);
            return fullPath.substring(fullPath.lastIndexOf('/') + 1);
        } catch (error) {
            return 'File';
        }
    };

    const truncateFileName = (fileName, maxLength = 20) => {
        if (fileName.length <= maxLength) return fileName;
        const extIndex = fileName.lastIndexOf('.');
        const extension = extIndex > -1 ? fileName.slice(extIndex) : '';
        const baseName = extIndex > -1 ? fileName.slice(0, extIndex) : fileName;

        return `${baseName.slice(0, maxLength - extension.length - 3)}...${extension}`;
    };

    const formatMessages = () => {
        const formattedMessages = [];
        let lastDate = '';
    
        messages.forEach((msg, index) => {
            const messageDate = new Intl.DateTimeFormat('pl-PL', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }).format(new Date(msg.timestamp));
    
            if (messageDate !== lastDate) {
                formattedMessages.push(
                    <div key={messageDate} className="date-separator">
                        {messageDate}
                    </div>
                );
                lastDate = messageDate;
            }
    
            // Проверяем senderId, а не sender
            const isOwnMessage = msg.senderId === user.uid;
            const senderName = isOwnMessage ? "Ty" : msg.sender;
    
            formattedMessages.push(
                <div key={msg.timestamp} className={`message-item ${isOwnMessage ? 'you' : 'other'}`}>
                    <strong>{senderName}:</strong>
                    <div className="message-content">
                        {msg.text && <div>{msg.text}</div>}
                        {msg.fileUrl && msg.fileType && msg.fileType.startsWith('image/') && (
                            <img
                                src={msg.fileUrl}
                                alt="Image"
                                style={{ maxWidth: '100%', cursor: 'pointer' }}
                                onClick={() => openImageModal(msg.fileUrl)}
                            />
                        )}
                        {msg.fileUrl && msg.fileType && !msg.fileType.startsWith('image/') && (
                            <div>
                                <FileOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
                                <a href={msg.fileUrl} download>
                                    {truncateFileName(extractFileName(msg.fileUrl))}
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="message-time">
                        {new Intl.DateTimeFormat('pl-PL', {
                            hour: 'numeric',
                            minute: 'numeric',
                        }).format(new Date(msg.timestamp))}
                        <span className="message-status">
                            {msg.isRead ? (
                                <>
                                    <span className="double-checks">✓✓</span>
                                    {msg.readAt && (
                                        <span className="read-time">
                                            (Przeczytano: {new Intl.DateTimeFormat('pl-PL', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                day: 'numeric',
                                                month: 'long',
                                                year: 'numeric',
                                            }).format(new Date(msg.readAt))})
                                        </span>
                                    )}
                                </>
                            ) : (
                                <span className="single-check">✓</span>
                            )}
                        </span>
                    </div>
                </div>
            );
        });
    
        formattedMessages.push(<div key="end" ref={messagesEndRef} />);
    
        return formattedMessages;
    };
    

    const renderChatHeader = () => {
        if (!selectedChat) return null;

        const admin = selectedChat.admins && selectedChat.admins.length > 0 ? selectedChat.admins[0] : null;

        return (
            <div>
                <h2>{selectedChat.chatName}</h2>
                <div style={{ color: 'gray', fontSize: '14px' }}>
                    {admin ? (
                        <div>Rozmowa z: {admin.firstName} {admin.lastName}</div>
                    ) : (
                        <div>Oczekiwanie na dołączenie obsługi</div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="chat-container">
            <div className="chat-list">
                <div className="chat-header">
                    <h2>Czaty</h2>
                    <Button type="primary" onClick={() => setIsCreateChatModalVisible(true)}>
                        Utwórz nowy czat
                    </Button>
                </div>
                <Divider orientation="left" plain>
                    Ostatnie rozmowy
                </Divider>
                <List
                    itemLayout="horizontal"
                    dataSource={chats}
                    renderItem={(chat) => {
                        const isUnread = unreadCounts[chat.id] > 0;
                        const textStyle = {
                            fontWeight: isUnread ? 'bold' : 'normal',
                        };

                        return (
                            <List.Item onClick={() => openChat(chat)}>
                                <List.Item.Meta
                                    title={
                                        <div>
                                            <Badge
                                                count={unreadCounts[chat.id] || 0}
                                                overflowCount={99}
                                                offset={[15, 5]}
                                                style={{
                                                    backgroundColor: '#ff4d4f',
                                                    color: '#fff',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <span style={textStyle}>
                                                    {chat.chatName}
                                                </span>
                                            </Badge>
                                        </div>
                                    }
                                    description={
                                        <>
                                            <div style={textStyle}>Status: {chat.status}</div>
                                            <div style={textStyle}>Utworzono: {new Date(chat.timestamp).toLocaleString('pl-PL')}</div>
                                            {chat.lastMessage && chat.lastMessage.text && (
                                                <>
                                                    <div style={textStyle}>
                                                        Ostatnia wiadomość: {new Date(chat.lastMessage.timestamp).toLocaleString('pl-PL')}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </List.Item>
                        );
                    }}
                />
            </div>

            <Modal
                title="Utwórz nowy czat"
                visible={isCreateChatModalVisible}
                onCancel={() => setIsCreateChatModalVisible(false)}
                onOk={createChat}
                okText="Utwórz"
                cancelText="Anuluj"
            >
                <Input
                    placeholder="Nazwa nowego czatu"
                    value={chatName}
                    onChange={(e) => setChatName(e.target.value)}
                />
            </Modal>

            <Modal
                title={renderChatHeader()}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                style={{ top: 20 }}
            >
                {selectedChat ? (
                    <div className="chat-window">
                        <div className="messages">{formatMessages()}</div>
                        {selectedChat.status === 'open' ? (
                            <div>
                                {previewImageUrl ? (
                                    <div className="image-preview">
                                        <img
                                            src={previewImageUrl}
                                            alt="Preview"
                                            onClick={removeSelectedImage}
                                            style={{ maxWidth: '100%', cursor: 'pointer' }}
                                        />
                                        <CloseCircleOutlined
                                            onClick={removeSelectedImage}
                                            style={{ position: 'absolute', top: 8, right: 8 }}
                                        />
                                    </div>
                                ) : selectedFile ? (
                                    <div className="file-preview">
                                        <FileOutlined style={{ fontSize: '48px' }} />
                                        <span>{selectedFile.name}</span>
                                        <CloseCircleOutlined
                                            onClick={removeSelectedImage}
                                            style={{ position: 'absolute', top: 8, right: 8 }}
                                        />
                                    </div>
                                ) : null}
                                <div className="message-input">
                                    <Input
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onPressEnter={sendMessage}
                                        placeholder="Napisz wiadomość..."
                                        style={{ border: '1px solid #ddd' }}
                                    />
                                    <Upload
                                        beforeUpload={() => false}
                                        onChange={handleFileChange}
                                        showUploadList={false}
                                        className="upload-button"
                                    >
                                        <Button icon={<UploadOutlined />} />
                                    </Upload>
                                    <Button type="primary" onClick={sendMessage}>
                                        Wyślij
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="chat-closed-message">
                                Czat zamknięty przez administratora.
                                <div>Żeby napisać wiadomość utwórz nowe zgłoszenie.</div>
                                <Button type="primary" onClick={() => setIsCreateChatModalVisible(true)}>
                                    Utwórz nowy czat
                                </Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="no-chat-selected">Proszę wybrać czat</div>
                )}
            </Modal>

            <Modal
                visible={isImageModalVisible}
                footer={null}
                onCancel={closeImageModal}
                style={{ top: 20 }}
            >
                <img src={modalImageUrl} alt="Modal Content" style={{ width: '100%' }} />
                <Button type="primary" href={modalImageUrl} download style={{ marginTop: '10px' }}>
                    Pobierz
                </Button>
            </Modal>
        </div>
    );
};

export default Pomoc;
