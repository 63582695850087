import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'antd';

const CameraCapture = ({ onCapture, isScanning }) => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const capture = () => {
        const video = webcamRef.current.video;
        const canvas = canvasRef.current;

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageSrc = canvas.toDataURL('image/jpeg', 1.0);
        onCapture(imageSrc);
    };

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                paddingTop: '133.33%', // Соотношение сторон 3:4
                backgroundColor: '#000',
                borderRadius: '16px',
            }}
        >
            {isScanning && (
                <>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            facingMode: 'environment',
                            width: 1920,  // Разрешение Full HD
                            height: 1080, // Соотношение сторон 16:9
                        }}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '16px',
                        }}
                    />
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '5%', 
                            left: '10%',
                            width: '80%',
                            height: '90%',
                            border: '1px dashed rgba(255, 255, 255, 0.8)',
                            borderRadius: '16px',
                            boxSizing: 'border-box',
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={capture}
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 1,
                        }}
                    >
                        Skanuj
                    </Button>
                </>
            )}
        </div>
    );
};

export default CameraCapture;
