import React, { useEffect } from 'react';
import { notification } from 'antd';
import { onMessageListener } from '../firebaseConfig';

const NotificationListener = () => {
  useEffect(() => {
    const handleNotification = async () => {
      onMessageListener()
        .then((payload) => {
          notification.open({
            message: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/logo512.png"
                  alt="icon"
                  style={{ width: 50, height: 50, marginRight: 10 }}
                />
                <div>
                  <div style={{ fontWeight: 'bold' }}>{payload.notification.title}</div>
                  <div>{payload.notification.body}</div>
                </div>
              </div>
            ),
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        })
        .catch((err) => console.log('Failed to receive notification: ', err));
    };

    handleNotification();
  }, []);

  return null;
};

export default NotificationListener;
