import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message, Row, Col, Typography, Space, Modal, Divider } from 'antd';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'; // Добавлено sendPasswordResetEmail
import { useNavigate } from 'react-router-dom';
import { auth, db, requestForToken } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import logo from '../assets/logo.png';

const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false); // Состояние для модального окна
  const [resetEmail, setResetEmail] = useState(''); // Состояние для ввода email при сбросе пароля

  const onFinish = async (values) => {
    const { username, password } = values;

    message.loading({ content: 'Logowanie...', key: 'login' });

    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const user = userCredential.user;

      const token = await requestForToken();

      if (token) {
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { fcmToken: token }, { merge: true });
      }

      message.success({ content: 'Pomyślnie zalogowano!', key: 'login', duration: 2 });
      navigate('/');
    } catch (error) {
      message.error({ content: 'Nie udało się zalogować. Sprawdź swoje dane logowania i spróbuj ponownie.', key: 'login', duration: 2 });
      console.error('Failed to login:', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  const showModal = () => {
    setIsModalVisible(true); // Открытие модального окна
  };

  const handleOk = async () => {
    // Обработка сброса пароля
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      message.success('Link do resetowania hasła został wysłany.');
      setIsModalVisible(false);
    } catch (error) {
      message.error('Nie udało się wysłać linku do resetowania hasła.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Закрытие модального окна
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', background: '#f0f2f5' }}>
      <header style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        backgroundColor: '#fff',
      }}>
        <img src={logo} alt="Logo" style={{ height: '40px' }} />
        <span style={{ fontSize: '16px' }}>Pomoc: +48 733 930 040</span>
      </header>
      <Row justify="center" style={{ flex: 1, alignItems: 'center', background: "white" }}>
        <Col xs={24} sm={16} md={12} lg={8}>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{
              margin: '20px',
              maxWidth: '100%',
              padding: '24px',
              backgroundColor: '#fff',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Title level={2} style={{ textAlign: 'left', marginBottom: '24px' }}>Logowanie do Panelu</Title>
            <Form.Item
              label="Adres email"
              name="username"
              rules={[{ required: true, message: 'Proszę wprowadzić nazwę użytkownika!' }]}
            >
              <Input placeholder="Wprowadź adres email" />
            </Form.Item>

            <Form.Item
              label="Hasło"
              name="password"
              rules={[{ required: true, message: 'Proszę wprowadzić hasło!' }]}
            >
              <Input.Password placeholder="Wprowadź hasło" />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ span: 24 }}
            >
              <Checkbox>Zapamiętaj mnie</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Zaloguj się
              </Button>
            </Form.Item>
            <Divider orientation="center" plain>
              Lub
            </Divider>
            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ width: '100%', display: 'flex', gap: '16px' }}>
                <Button
                  type="primary"
                  onClick={showModal}
                  style={{ flex: 1, fontSize: '14px', padding: '10px', fontWeight: 'normal' }}
                >
                  Zresetuj hasło
                </Button>
                <Button
                  type="default"
                  onClick={navigateToRegister}
                  style={{ flex: 1, fontSize: '14px', padding: '10px', fontWeight: 'normal' }}
                >
                  Zarejestruj się
                </Button>
              </div>
            </Form.Item>



          </Form>
        </Col>
      </Row>

      {/* Модальное окно для сброса пароля */}
      <Modal
        title="Zresetuj hasło"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Wyślij link resetujący"
        cancelText="Anuluj"
      >
        <Form.Item
          label="Adres email"
          rules={[{ required: true, message: 'Proszę wprowadzić adres email!' }]}
        >
          <Input
            placeholder="Wprowadź adres email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default Login;
