import React, { useState } from 'react';
import { Form, Input, Button, Typography, message, Row, Col, Space } from 'antd';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig'; // Обновите импорт в зависимости от вашей конфигурации
import { doc, setDoc } from 'firebase/firestore';
import logo from '../assets/logo.png'; // Путь к вашему логотипу

const { Title, Text } = Typography;

const Register = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();

  const updateStatusMessage = (message) => {
    setStatusMessage(message);
  };

  const checkPasswordCriteria = (password) => {
    const lower = /(?=.*[a-z])/;
    const upper = /(?=.*[A-Z])/;
    const number = /(?=.*[0-9])/;
    const special = /(?=.*[@$!%*?&])/;
    const length = /(?=.{8,})/;

    const lowerValid = lower.test(password);
    const upperValid = upper.test(password);
    const numberValid = number.test(password);
    const specialValid = special.test(password);
    const lengthValid = length.test(password);

    return lowerValid && upperValid && numberValid && specialValid && lengthValid;
  };

  const handleRegister = async (values) => {
    const { email, password } = values;

    if (!checkPasswordCriteria(password)) {
      updateStatusMessage('Hasło nie spełnia wymagań.');
      return;
    }

    if (password !== confirmPassword) {
      updateStatusMessage('Hasła nie są identyczne.');
      return;
    }

    message.loading('Rejestracja w toku...', 0);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Создание записи пользователя в Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: email,
        registeredAt: new Date(),
        role: 'user',
        status: 'Wymagana weryfikacja KYC',
      });

      message.success('Rejestracja zakończona pomyślnie!');
      // Перенаправление пользователя на страницу входа
      navigate('/');
      
    } catch (error) {
      message.error('Błąd rejestracji: ' + error.message);
      console.error('Error during registration:', error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', background: '#f0f2f5' }}>
      <header style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '16px 24px', 
        backgroundColor: '#fff', 
      }}>
        <img src={logo} alt="Logo" style={{ height: '40px' }} />
        <span style={{ fontSize: '16px' }}>Pomoc: +48 733 930 040</span>
      </header>
      <Row justify="center" style={{ flex: 1, alignItems: 'center', background: "white" }}>
        <Col xs={24} sm={16} md={12} lg={8}>
          <Form
            name="register"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ 
              margin: '20px',
              maxWidth: '100%', 
              padding: '24px', 
              backgroundColor: '#fff', 
              border: '1px solid #d9d9d9', 
              borderRadius: '8px',
            }}
            initialValues={{ remember: true }}
            onFinish={handleRegister}
            autoComplete="off"
          >
            <Title level={2} style={{ textAlign: 'left', marginBottom: '24px' }}>Rejestracja</Title>
            
            <Form.Item
              label="Adres email"
              name="email"
              rules={[{ required: true, message: 'Proszę wprowadzić adres email!' }]}
            >
              <Input placeholder="Wprowadź adres email" />
            </Form.Item>

            <Form.Item
              label="Hasło"
              name="password"
              rules={[{ required: true, message: 'Proszę wprowadzić hasło!' }]}
            >
              <Input.Password 
                placeholder="Wprowadź hasło" 
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Potwierdź hasło"
              name="confirmPassword"
              rules={[{ required: true, message: 'Proszę potwierdzić hasło!' }]}
            >
              <Input.Password 
                placeholder="Potwierdź hasło" 
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Zarejestruj się
              </Button>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Space direction="vertical" size="middle" style={{ width: '100%', textAlign: 'center' }}>
                <Text>{statusMessage}</Text>
                <Text>Masz już konto? <Button type="link" onClick={() => navigate('/login')}>Zaloguj się</Button></Text>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
