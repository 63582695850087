import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig'; // Импорт Firestore и Auth из конфигурации
import { collection, getDocs } from 'firebase/firestore';
import { Modal, Button } from 'antd';
import 'antd/dist/reset.css'; // Импорт стилей Ant Design

const Dokumenty = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true); // Состояние для загрузки
  const [error, setError] = useState(null); // Состояние для ошибок
  const [visible, setVisible] = useState(false); // Видимость модального окна
  const [selectedDocument, setSelectedDocument] = useState(null); // Выбранный документ

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const userId = auth.currentUser?.uid; // Получение текущего пользователя
        if (userId) {
          // Путь к коллекции документов
          const docRef = collection(db, `users/${userId}/umowy`);

          // Получаем все документы в коллекции
          const querySnapshot = await getDocs(docRef);
          const docs = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setDocuments(docs);
        } else {
          setError("User not authenticated.");
        }
      } catch (error) {
        setError("Error fetching documents: " + error.message);
      } finally {
        setLoading(false); // Устанавливаем состояние загрузки в false
      }
    };

    fetchDocuments();
  }, []);

  const handleOpenModal = (doc) => {
    setSelectedDocument(doc);
    setVisible(true);
  };

  const handleDownload = () => {
    if (selectedDocument?.pdfUrl) {
      // Создаем временную ссылку для скачивания
      const link = document.createElement('a');
      link.href = selectedDocument.pdfUrl;
      link.download = selectedDocument.title || 'document.pdf'; // Имя файла для скачивания
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedDocument(null);
  };

  if (loading) {
    return <p>Loading documents...</p>; // Индикатор загрузки
  }

  if (error) {
    return <p>{error}</p>; // Сообщение об ошибке
  }

  return (
    <div>
      {documents.length > 0 ? (
        documents.map((doc) => (
          <div
            key={doc.id}
            style={{
              marginBottom: '20px',
              border: '1px solid #d9d9d9',
              borderRadius: '12px',
              padding: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <h3 style={{ margin: 0 }}>{doc.id || 'No title available'}</h3> {/* Название документа */}
              <p style={{ margin: '5px 0' }}>Podpisano: {doc.signedAt ? new Date(doc.signedAt.seconds * 1000).toLocaleString('en-GB', { timeZone: 'Europe/Berlin' }) : 'No date available'}</p> {/* Дата подписания */}
            </div>
            <Button type="primary" onClick={() => handleOpenModal(doc)}>
              Zobacz
            </Button>
          </div>
        ))
      ) : (
        <p>No documents available.</p>
      )}

      {selectedDocument && (
        <Modal
          title={selectedDocument.id || 'Document'}
          visible={visible}
          onCancel={handleCancel}

        >
          <iframe
            src={selectedDocument.pdfUrl}
            width="100%"
            height="500px"
            title="Document Preview"
            style={{ border: 'none' }}
          ></iframe>
        </Modal>
      )}
    </div>
  );
};

export default Dokumenty;
