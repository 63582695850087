import React, { useEffect, useState } from 'react';
import { getDatabase, ref, query, orderByChild, limitToLast, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Card, Modal, Button, Pagination, Alert } from 'antd';

const Faktury = () => {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5); // Количество фактур на странице
  const [selectedFileURL, setSelectedFileURL] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [driverId, setDriverId] = useState('');

  useEffect(() => {
    const fetchDriverId = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const firestore = getFirestore();
        const userRef = doc(firestore, `users/${user.uid}`);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setDriverId(userData.driverId);
        } else {
          console.error('User document does not exist.');
        }
      }
    };

    fetchDriverId();
  }, []);

  useEffect(() => {
    if (driverId) {
      const fetchInvoices = async () => {
        const db = getDatabase();
        const invoicesRef = ref(db, `drivers/${driverId}/invoices`);
        const invoicesQuery = query(invoicesRef, orderByChild('timestamp'), limitToLast(100)); // Загружаем последние 100 записей

        try {
          const snapshot = await get(invoicesQuery);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const sortedInvoices = Object.values(data)
              .filter((invoice) => invoice.timestamp) // Фильтрация записей без timestamp
              .sort((a, b) => b.timestamp - a.timestamp); // Сортировка по timestamp
            setInvoices(sortedInvoices);
          }
        } catch (error) {
          console.error('Ошибка при получении фактур:', error);
        }
      };

      fetchInvoices();
    }
  }, [driverId]);

  const openModal = (fileURL) => {
    setSelectedFileURL(fileURL);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedFileURL('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Определяем начальный и конечный индекс для пагинации
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentInvoices = invoices.slice(startIndex, endIndex);

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', alignItems: 'center'}}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {currentInvoices.map((invoice, index) => (
          <Card
            key={index}
            title={`Faktura: ${invoice.numerfaktury}`}
            extra={<Button onClick={() => openModal(invoice.fileURL)}>Zobacz fakturę</Button>}
            style={{ width: '100%' }}
          >
            <p>Kwota brutto: {invoice.grossAmount} PLN</p>
            <p>Zwrot VAT: {invoice.vatReturn} PLN</p>
            <p>Data zakupu: {invoice.purchaseDate}</p>
            <p>Data dodania: {new Date(invoice.timestamp).toLocaleString()}</p>
            <Alert
              message={`Status: ${invoice.status}`}
              type={
                invoice.status === 'zaakceptowany'
                  ? 'success'
                  : invoice.status === 'W trakcie sprawdzenia'
                  ? 'warning'
                  : 'error'
              }
              showIcon
            />
          </Card>
        ))}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={invoices.length}
          onChange={handlePageChange}
          style={{ marginTop: '20px' }}
        />
      </div>

      <Modal visible={isModalVisible} footer={null} onCancel={closeModal}>
        <img src={selectedFileURL} alt="Faktura" style={{ width: '100%' }} />
      </Modal>
    </div>
  );
};

export default Faktury;
