import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
import { HomeFilled, DollarCircleFilled, FileFilled, SettingFilled, CarFilled, UserOutlined, MessageFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate

const { Title } = Typography;

const ControlCard = () => {
  const navigate = useNavigate(); // Используем useNavigate для навигации

  const buttonWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Центрируем содержимое
    justifyContent: 'center',
    width: '100%',
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    width: '100%', // Кнопка растягивается на всю ширину Col
    borderRadius: '16px',
    backgroundColor: '#f5f5f5',
    border: 'none',
    marginBottom: '8px',
  };

  const iconStyle = {
    fontSize: '18px',
    color: '#333',
  };

  const textStyle = {
    fontSize: '14px',
    fontWeight: '500',
    textAlign: 'center', // Центрируем текст
    color: '#333',
    
  };

  return (
    <div style={{ padding: '10px' }}>
      <Title level={4} style={{ textAlign: 'left' }}>Panel sterowania:</Title>
      <Row gutter={[16, 16]} justify="start">
            <Col xs={8} style={buttonWrapperStyle}>
                <Button style={buttonStyle} onClick={() => navigate('/faktury')}>
                    <FileFilled style={iconStyle} />
                </Button>
                <div style={textStyle}>Faktury</div>
            </Col>
            <Col xs={8} style={buttonWrapperStyle}>
                <Button style={buttonStyle} onClick={() => navigate('/rozliczenia')}>
                    <DollarCircleFilled style={iconStyle} />
                </Button>
                <div style={textStyle}>Rozliczenie</div>
            </Col>
            <Col xs={8} style={buttonWrapperStyle}>
                <Button style={buttonStyle} onClick={() => navigate('/pomoc')}>
                    <MessageFilled style={iconStyle} />
                </Button>
                <div style={textStyle}>Pomoc</div>
            </Col>
            <Col xs={8} style={buttonWrapperStyle}>
                <Button style={buttonStyle} onClick={() => navigate('/samochod')}>
                    <CarFilled style={iconStyle} />
                </Button>
                <div style={textStyle}>Samochód</div>
            </Col>
            <Col xs={8} style={buttonWrapperStyle}>
                <Button style={buttonStyle} onClick={() => navigate('/dokumenty')}>
                    <HomeFilled style={iconStyle} />
                </Button>
                <div style={textStyle}>Dokumenty</div>
            </Col>
            <Col xs={8} style={buttonWrapperStyle}>
                <Button style={buttonStyle} onClick={() => navigate('/konto')}>
                    <UserOutlined style={iconStyle} />
                </Button>
                <div style={textStyle}>Konto</div>
            </Col>
        </Row>
    
    </div>
  );
};

export default ControlCard;
