import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeFilled, DollarCircleFilled, FileFilled, PlusOutlined, MessageFilled } from '@ant-design/icons';
import { Modal, Button, Form, Collapse, message, Result, Badge, Input, Radio } from 'antd';
import InvoiceProcessor from './InvoiceProcessor';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getDatabase, ref as dbRef, set, onValue } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import Lottie from 'lottie-react';
import successAnimation from './lottie/success.json';

import './MobileMenu.css';

const { Panel } = Collapse;

const MobileMenu = () => {
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(null);
    const [editing, setEditing] = useState(false);
    const [isAddingNewInvoice, setIsAddingNewInvoice] = useState(true);
    const [driverId, setDriverId] = useState(null);
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        const fetchDriverId = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const db = getFirestore();
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setDriverId(userDoc.data().driverId);
                }
            }
        };

        fetchDriverId();
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const db = getDatabase();
            const chatsRef = dbRef(db, 'chats');

            onValue(chatsRef, (snapshot) => {
                let count = 0;
                snapshot.forEach((chatSnap) => {
                    const chat = chatSnap.val();
                    const chatId = chatSnap.key;

                    if (chat.creatorId === user.uid) {
                        const messagesRef = dbRef(db, `chats/${chatId}/messages`);

                        onValue(messagesRef, (messagesSnapshot) => {
                            messagesSnapshot.forEach((messageSnap) => {
                                const message = messageSnap.val();
                                if (!message.isRead && message.sender !== user.email) {
                                    count += 1;
                                }
                            });
                            setUnreadCount(count);
                        });
                    }
                });
            });
        }
    }, []);

    const showModal = (event) => {
        event.preventDefault();
        setIsModalVisible(true);
        setCurrentInvoiceIndex(null);
        setEditing(false);
        setIsAddingNewInvoice(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditing(false);
        setIsAddingNewInvoice(false);
        setInvoices([]);
        setIsSaveSuccessful(false);
    };

    const handleProcessComplete = (data) => {
        data.documentId = uuidv4();
        data.timestamp = Date.now();
        data.vatReturn = data.vatAmount ? (parseFloat(data.vatAmount) * 0.5).toFixed(2) : '0.00';
        const isAccepted = data.isBuyerAddressGrabiszynska && data.isBuyerColorPartner && data.isImageReadable && data.isOnlyFuel;
        data.status = isAccepted ? 'zaakceptowany' : 'odrzucony';

        setInvoices(prevInvoices => {
            const existingIndex = prevInvoices.findIndex(
                invoice => invoice.numerfaktury === data.numerfaktury
            );

            if (existingIndex !== -1) {
                const updatedInvoices = [...prevInvoices];
                updatedInvoices[existingIndex] = data;
                return updatedInvoices;
            } else {
                return [...prevInvoices, data];
            }
        });

        setEditing(false);
        setIsAddingNewInvoice(false);
    };

    const handleEdit = (index) => {
        setCurrentInvoiceIndex(index);
        setEditing(true);
        setIsAddingNewInvoice(false);
        setIsModalVisible(true);
    };

    const handleSave = (index) => {
        const updatedInvoice = invoices[index];
        setInvoices(prevInvoices => {
            const updatedInvoices = [...prevInvoices];
            updatedInvoices[index] = { ...updatedInvoices[index], ...updatedInvoice };
            return updatedInvoices;
        });
        setEditing(false);
        setIsAddingNewInvoice(false);
    };

    const saveAllInvoices = async () => {
        if (!driverId) {
            message.error('Nie udało się uzyskać driverId. Spróbuj ponownie później.');
            return;
        }

        const db = getDatabase();
        const promises = invoices.map((invoice) => {
            const invoiceRef = dbRef(db, `drivers/${driverId}/invoices/${invoice.documentId}`);
            return set(invoiceRef, invoice);
        });

        try {
            await Promise.all(promises);
            message.success('Wszystkie faktury zostały pomyślnie zapisane w bazie danych!');
            setIsSaveSuccessful(true);
        } catch (error) {
            message.error('Błąd podczas zapisywania faktur w bazie danych.');
        }
    };

    const addMoreInvoices = () => {
        setCurrentInvoiceIndex(null);
        setEditing(false);
        setIsAddingNewInvoice(true);
    };

    return (
        <div className="mobile-menu">
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                <HomeFilled />
            </Link>

            <Link to="/rozliczenia" className={location.pathname === '/rozliczenia' ? 'active' : ''}>
                <DollarCircleFilled />
            </Link>

            <Link to="/samochod" className={location.pathname === '/samochod' ? 'active' : ''} onClick={showModal}>
                <PlusOutlined />
            </Link>

            <Link to="/faktury" className={location.pathname === '/faktury' ? 'active' : ''}>
                <FileFilled />
            </Link>
            <Link to="/pomoc" className={location.pathname === '/pomoc' ? 'active' : ''}>
                <Badge
                    count={unreadCount}
                    overflowCount={99}
                    offset={[0, 0]}
                    style={{ backgroundColor: '#ff4d4f', color: '#fff', boxShadow: 'none' }}
                >
                    <MessageFilled style={{ fontSize: '24px', color: location.pathname === '/pomoc' ? '#000' : '#8c8c8c' }} />
                </Badge>
            </Link>


            <Modal
                title="Nowa Faktura"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={
                    invoices.length > 0 && !isSaveSuccessful ? (
                        <>
                            <Button key="save-all" type="primary" onClick={saveAllInvoices}>
                                Zapisz Wszystkie
                            </Button>
                            <Button key="cancel" onClick={handleCancel}>
                                Anuluj
                            </Button>
                        </>
                    ) : null
                }
            >
                {isSaveSuccessful ? (
                    <Result
                        status="success"
                        title="Wszystkie faktury zostały pomyślnie zapisane!"
                        icon={<Lottie animationData={successAnimation} loop={false} />}
                        extra={[
                            <Button type="primary" key="close" onClick={handleCancel}>
                                Zamknij
                            </Button>,
                        ]}
                    />
                ) : (
                    <>
                        {invoices.length > 0 && (
                            <div style={{ marginBottom: 20 }}>
                                <h3>Lista Faktur:</h3>
                                <Collapse accordion>
                                    {invoices.map((invoice, index) => (
                                        <Panel
                                            header={`Faktura ${invoice.numerfaktury} - ${invoice.grossAmount} PLN (${invoice.status})`}
                                            key={invoice.documentId || index}
                                            extra={<Button onClick={() => handleEdit(index)}>Edytuj</Button>}
                                        >
                                            <Form
                                                layout="vertical"
                                                initialValues={invoice}
                                                onValuesChange={(changedValues, allValues) => {
                                                    setInvoices(prevInvoices => {
                                                        const updatedInvoices = [...prevInvoices];
                                                        updatedInvoices[index] = { ...updatedInvoices[index], ...allValues };
                                                        return updatedInvoices;
                                                    });
                                                }}
                                                key={invoice.documentId} 
                                            >
                                                <Form.Item label="Rodzaj paliwa" name="fuelType">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Kwota brutto" name="grossAmount">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Adres nabywcy zawiera 'GRABISZYŃSKА'" name="isBuyerAddressGrabiszynska">
                                                    <Radio.Group disabled={true}>
                                                        <Radio value={true}>Tak</Radio>
                                                        <Radio value={false}>Nie</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item label="Nabywca 'COLOR PARTNER'" name="isBuyerColorPartner">
                                                    <Radio.Group disabled={true}>
                                                        <Radio value={true}>Tak</Radio>
                                                        <Radio value={false}>Nie</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item label="Obraz jest czytelny" name="isImageReadable">
                                                    <Radio.Group disabled={true}>
                                                        <Radio value={true}>Tak</Radio>
                                                        <Radio value={false}>Nie</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item label="Tekst zawiera tylko informacje o paliwie" name="isOnlyFuel">
                                                    <Radio.Group disabled={true}>
                                                        <Radio value={true}>Tak</Radio>
                                                        <Radio value={false}>Nie</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item label="Liczba litrów" name="liters">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Kwota netto" name="netAmount">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="NIP sprzedawcy" name="nipseller">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Numer faktury" name="numerfaktury">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Data zakupu" name="purchaseDate">
                                                    <Input disabled={!editing}  />
                                                </Form.Item>
                                                <Form.Item label="Numer rejestracyjny" name="registrationNumber">
                                                    <Input disabled={!editing}  />
                                                </Form.Item>
                                                <Form.Item label="Typ dokumentu" name="type">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Kwota VAT" name="vatAmount">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Stawka VAT" name="vatRate">
                                                    <Input disabled={true}  />
                                                </Form.Item>
                                                <Form.Item label="Kwota zwrotu VAT" name="vatReturn">
                                                    <Input disabled={true} value={invoice.vatReturn} />
                                                </Form.Item>
                                                <Form.Item label="Plik z fakturą">
                                                    <a href={invoice.fileURL} target="_blank" rel="noopener noreferrer">Pobierz fakturę</a>
                                                </Form.Item>
                                                {editing && (
                                                    <Form.Item>
                                                        <Button type="primary" onClick={() => handleSave(index)}>
                                                            Zapisz
                                                        </Button>
                                                    </Form.Item>
                                                )}
                                            </Form>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                        )}

                        {isAddingNewInvoice && (
                            <InvoiceProcessor onProcessComplete={handleProcessComplete} />
                        )}

                        {!isAddingNewInvoice && (
                            <Button type="primary" onClick={addMoreInvoices} style={{ width: '100%', marginBottom: 10 }}>
                                Dodaj więcej
                            </Button>
                        )}
                    </>
                )}
            </Modal>
        </div>
    );
};

export default MobileMenu;
