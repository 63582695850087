// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import MobileMenu from './components/MobileMenu';
import Rozliczenia from './pages/Rozliczenia';
import Faktury from './pages/Faktury';
import Samochod from './pages/Samochod';
import Konto from './pages/Konto';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Pomoc from './pages/Pomoc';
import AppHeader from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { UserStatusProvider } from './contexts/UserStatusContext'; // Подключаем наш новый контекст
import Dokumenty from './pages/Dokumenty';
import NotificationListener from './components/NotificationListener';

const { Content } = Layout;

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#e4b61a',
          borderRadius: 10,
        },
      }}
    >
      <AuthProvider>
        <Router>
        <NotificationListener />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="*"
              element={
                <Layout style={{ backgroundColor: '#fff' }}>
                  <AppHeader />
                  <Layout style={{ backgroundColor: '#fff' }}>
                    <Content style={{ padding: '20px', marginBottom: '80px' }}>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider> {/* Оборачиваем защищенные маршруты в UserStatusProvider */}
                                <Home />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/rozliczenia"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider>
                                <Rozliczenia />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/faktury"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider>
                                <Faktury />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/samochod"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider>
                                <Samochod />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/konto"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider>
                                <Konto />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/pomoc"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider>
                                <Pomoc />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/dokumenty"
                          element={
                            <PrivateRoute>
                              <UserStatusProvider>
                                <Dokumenty />
                              </UserStatusProvider>
                            </PrivateRoute>
                          }
                        />
                      </Routes>

                    </Content>
                    <MobileMenu />
                  </Layout>
                </Layout>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
