import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db, realtimeDb } from '../firebaseConfig';
import { ref, get } from 'firebase/database';
import WeeklySummaryCard from './WeeklySummaryCard';
import ControlCard from './ControlCard';
import ReferralProgram from './ReferralProgram';
import InvoicesList from './InvoicesList';
import { Layout, ConfigProvider, App as AntdApp } from 'antd';

const Home = () => {
  const { currentUser } = useAuth();
  const [driverId, setDriverId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [weekData, setWeekData] = useState({
    startDate: 'N/A',
    endDate: 'N/A',
    total: 0,
    status: 'Loading...',
  });
  const [summaryData, setSummaryData] = useState({});
  const [appIncomes, setAppIncomes] = useState({});

  // Перемещаем calculateNetto выше
  const calculateNetto = useCallback((appData) => {
    const fields = ['kursy', 'przychod_dodatkowy', 'commission'];
    return fields.reduce((sum, field) => sum + (Number(appData[field]) || 0), 0);
  }, []);

  const fetchDriverSummaryData = useCallback(async (driverId, weekId, signal) => {
    try {
      const refPath = `drivers/${driverId}/weeks/${weekId}/summary`;
      const summaryRef = ref(realtimeDb, refPath);

      const snapshot = await get(summaryRef);
      const summary = snapshot.val();
      if (summary) {
        setSummaryData(summary);
      } else {
        console.error('Dane podsumowujące nie istnieją.');
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error('Error fetching summary data:', error);
      }
    }
  }, []);

  const displayUserData = useCallback(async (driverId, weekId, signal) => {
    try {
      const weekRef = ref(realtimeDb, `drivers/${driverId}/weeks/${weekId}/apps`);
      const snapshot = await get(weekRef);
      const weekData = snapshot.val();
      let totalNettoSum = 0;
      const incomes = {};

      if (weekData) {
        for (let appId in weekData) {
          const netto = calculateNetto(weekData[appId]);
          totalNettoSum += netto;
          incomes[appId] = netto;
        }
      }
      setAppIncomes(incomes);
    } catch (error) {
      if (!signal.aborted) {
        console.error('Error displaying user data:', error);
      }
    }
  }, [calculateNetto]);

  const fetchLatestWeekData = useCallback(async (driverId, signal) => {
    try {
      const driversRef = ref(realtimeDb, `drivers/${driverId}/weeks`);
      const snapshot = await get(driversRef);

      if (snapshot.exists()) {
        const weeks = snapshot.val();
        const sortedWeeks = Object.entries(weeks).sort(([aKey], [bKey]) => {
          const [aWeekNumber, aYear] = aKey.split('-').map(Number);
          const [bWeekNumber, bYear] = bKey.split('-').map(Number);

          if (aYear !== bYear) {
            return bYear - aYear;
          } else {
            return bWeekNumber - aWeekNumber;
          }
        });

        const latestCompletedWeek = sortedWeeks.find(
          ([, weekData]) => weekData.summary?.status === "Rozliczenie zakończone"
        );

        if (latestCompletedWeek) {
          const weekKey = latestCompletedWeek[0];
          const weekData = latestCompletedWeek[1];
          setWeekData({
            startDate: weekData.summary?.startDate || 'N/A',
            endDate: weekData.summary?.endDate || 'N/A',
            total: weekData.summary?.total?.toFixed(2) || 0,
            status: weekData.summary?.status || 'N/A',
          });
          await fetchDriverSummaryData(driverId, weekKey, signal);
          await displayUserData(driverId, weekKey, signal);
        } else {
          console.log("No completed week data available.");
        }
      } else {
        console.log("No week data available.");
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error("Error fetching week data:", error);
      }
    } finally {
      if (!signal.aborted) setLoading(false);
    }
  }, [fetchDriverSummaryData, displayUserData]);

  const fetchDriverId = useCallback(async (signal) => {
    try {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFirstName(data.firstName || '');
          const driverId = data.driverId;
          setDriverId(driverId);
          await fetchLatestWeekData(driverId, signal);
        } else {
          console.error('No such document!');
          setLoading(false);
        }
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    }
  }, [currentUser, fetchLatestWeekData]);

  const calculateTotalIncome = useCallback(() => {
    return Object.values(appIncomes).reduce((sum, value) => sum + value, summaryData.bonusPartnera || 0) + (summaryData.vat_bonus || 0);
  }, [appIncomes, summaryData]);

  const calculateTotalExpenses = useCallback(() => {
    const totalExpenses =
      (summaryData.vat_dodatkowy || 0) +
      (summaryData.vat_przejazdy || 0) +
      (summaryData.wynajem || 0) +
      (summaryData.partner || 0) +
      (summaryData.zus || 0) +
      (summaryData.inne || 0);

    return Number(totalExpenses);
  }, [summaryData]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    
    fetchDriverId(signal);

    return () => {
      controller.abort(); // Отменяем запросы при размонтировании компонента
    };
  }, [fetchDriverId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>

        <WeeklySummaryCard
          weekData={weekData}
          summaryData={summaryData}
          appIncomes={appIncomes}
          calculateTotalIncome={calculateTotalIncome}
          calculateTotalExpenses={calculateTotalExpenses}
        />
        <ControlCard />
        <ReferralProgram />
        <InvoicesList />

    </div>
  );
};

export default Home;
