import React, { useEffect, useState } from 'react';
import { Collapse, Button, message, Modal, Form, Input, Select } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { SettingOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const Konto = () => {
  const [personalData, setPersonalData] = useState({});
  const [taxData, setTaxData] = useState({});
  const [carData, setCarData] = useState({});
  const [userData, setUserData] = useState({});
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [editingData, setEditingData] = useState({});
  const [editingField, setEditingField] = useState('');
  const [isPeriodModalVisible, setIsPeriodModalVisible] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(userData.okresrozliczeniowy || ''); // Значение по умолчанию

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;

        try {
          const personalInfoRef = doc(firestore, `users/${userId}/dokumenty/dane_personalne`);
          const personalInfoDoc = await getDoc(personalInfoRef);
          if (personalInfoDoc.exists()) {
            setPersonalData(personalInfoDoc.data());
          }

          const taxInfoRef = doc(firestore, `users/${userId}/dokumenty/dane_podatkowe`);
          const taxInfoDoc = await getDoc(taxInfoRef);
          if (taxInfoDoc.exists()) {
            setTaxData(taxInfoDoc.data());
          }

          const carInfoRef = doc(firestore, `users/${userId}/dokumenty/samochod`);
          const carInfoDoc = await getDoc(carInfoRef);
          if (carInfoDoc.exists()) {
            setCarData(carInfoDoc.data());
          }

          const userRef = doc(firestore, `users/${userId}`);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }
        } catch (error) {
          console.error('Error getting document:', error);
        }
      } else {
        window.location.href = '/login';
      }
    };

    fetchUserData();
  }, [auth, firestore]);

  const handleLogout = () => {
    message.loading({ content: 'Wylogowywanie...', key: 'logout' });
    signOut(auth)
      .then(() => {
        message.success({ content: 'Pomyślnie wylogowano!', key: 'logout', duration: 2 });
        window.location.href = '/login';
      })
      .catch((error) => {
        message.error({ content: `Błąd podczas wylogowywania: ${error.message}`, key: 'logout', duration: 2 });
      });
  };

  const showImageModal = (imageUrl) => {
    setModalImage(imageUrl);
    setIsImageModalVisible(true);
  };

  const handleImageModalOk = () => {
    setIsImageModalVisible(false);
  };

  const openEditModal = (field, data) => {
    setEditingField(field);
    setEditingData(data);
    setIsEditModalVisible(true);
  };

  const handleSave = async () => {
    if (editingField === 'tax' && editingData.rachunekBankowy.length !== 26) {
      message.error('Numer konta bankowego musi zawierać dokładnie 26 cyfr.');
      return; // Останавливаем сохранение
    }

    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      let updateRef;

      if (editingField === 'tax') {
        updateRef = doc(firestore, `users/${userId}/dokumenty/dane_podatkowe`);
      } else if (editingField === 'personal') {
        updateRef = doc(firestore, `users/${userId}/dokumenty/dane_personalne`);
      }

      try {
        await updateDoc(updateRef, editingData);
        message.success('Dane zostały pomyślnie zaktualizowane');
        setIsEditModalVisible(false);

        if (editingField === 'tax') {
          setTaxData(editingData);
        } else if (editingField === 'personal') {
          setPersonalData(editingData);
        }
      } catch (error) {
        message.error(`Błąd podczas aktualizacji danych: ${error.message}`);
      }
    }
  };
  const genExtra = (field, data) => (
    <SettingOutlined
      onClick={(event) => {
        event.stopPropagation();
        openEditModal(field, data);
      }}
    />
  );
  // Открыть модальное окно для редактирования okres rozliczeniowy
  const openPeriodEditModal = () => {
    setSelectedPeriod(userData.okresrozliczeniowy || ''); // Установим текущее значение
    setIsPeriodModalVisible(true);
  };

  // Сохранение периода в базе данных
  const handleSavePeriod = async () => {
    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      const userRef = doc(firestore, `users/${userId}`);

      try {
        await updateDoc(userRef, { okresrozliczeniowy: selectedPeriod });
        message.success('Okres rozliczeniowy został zaktualizowany');
        setIsPeriodModalVisible(false);
        setUserData({ ...userData, okresrozliczeniowy: selectedPeriod }); // Обновляем данные на UI
      } catch (error) {
        message.error(`Błąd podczas aktualizacji okresu: ${error.message}`);
      }
    }
  };

  return (
    <>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Informacje osobiste" key="1">
          <p>Imię: {userData.firstName || 'Brak danych'}</p>
          <p>Nazwisko: {userData.lastName || 'Brak danych'}</p>
          <p>Telefon: {userData.phoneNumber || 'Brak danych'}</p>
          <p>Obywatelstwo: {personalData.citizenship || 'Brak danych'}</p>
          <p>Miasto: {personalData.city || 'Brak danych'}</p>
          <p>Adres korespondencyjny: {personalData.correspondenceAddress || 'Brak danych'}</p>
          <p>Data urodzenia: {userData.dob || 'Brak danych'}</p>
          <p>PESEL: {personalData.pesel || 'Brak danych'}</p>
          <p>Kod pocztowy: {personalData.postalCode || 'Brak danych'}</p>
          <p>Adres zameldowania: {personalData.registrationAddress || 'Brak danych'}</p>
          <p>Adres zamieszkania: {personalData.residenceAddress || 'Brak danych'}</p>
          <p>Ulica: {personalData.street || 'Brak danych'}</p>
        </Panel>

        <Panel header="Informacje podatkowe" key="3" extra={genExtra('tax', taxData)}>
          <p>Konto bankowe: {taxData.rachunekBankowy || 'Brak danych'}</p>
          <p>Urząd skarbowy: {taxData.urzadSkarbowy || 'Brak danych'}</p>
        </Panel>

        <Panel header="Informacje o samochodzie" key="4">
          <p>Marka: {carData.carMake || 'Brak danych'}</p>
          <p>Model: {carData.carModel || 'Brak danych'}</p>
          <p>Własność: {carData.carOwnership || 'Brak danych'}</p>
          <p>Numer rejestracyjny: {carData.carRegistrationNumber || 'Brak danych'}</p>
          <p>VIN: {carData.carVin || 'Brak danych'}</p>
          <p>Rok: {carData.carYear || 'Brak danych'}</p>
          <Button onClick={() => showImageModal(carData.dowodPrzod)} style={{ marginTop: 20, width: '100%', borderRadius: '10px' }}>Zobacz dokument (przód)</Button>
          <Button onClick={() => showImageModal(carData.dowodTyl)} style={{ marginTop: 20, width: '100%', borderRadius: '10px' }}>Zobacz dokument (tył)</Button>
          <Button onClick={() => showImageModal(carData.ubezpieczenie)} style={{ marginTop: 20, width: '100%', borderRadius: '10px' }}>Ubezpieczenie</Button>
        </Panel>

        <Panel header="Informacje o użytkowniku" key="2">
          <p>Kraj: {userData.country || 'Brak danych'}</p>
          <p>ID kierowcy: {userData.driverId || 'Brak danych'}</p>
          <p>Email: {userData.email || 'Brak danych'}</p>
          <p>
          Okres rozliczeniowy: {userData.okresrozliczeniowy || 'Brak danych'}
          <SettingOutlined style={{ marginLeft: 8 }} onClick={openPeriodEditModal} />
        </p>
                  <p>Zarejestrowany: {userData.registeredAt ? new Date(userData.registeredAt.seconds * 1000).toLocaleString() : 'Brak danych'}</p>
          <p>Rola: {userData.role || 'Brak danych'}</p>
          <p>Status: {userData.status || 'Brak danych'}</p>
          <p>ID aplikanta Sumsub: {userData.sumsubApplicantId || 'Brak danych'}</p>
        </Panel>
      </Collapse>

      <Button onClick={handleLogout} style={{ marginTop: 20, width: '100%', borderRadius: '10px' }}>
        Wyloguj się
      </Button>

      {/* Модальное окно для показа изображения документа */}
      <Modal
        visible={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        footer={[
          <Button key="ok" type="primary" onClick={handleImageModalOk}>
            OK
          </Button>,
        ]}
      >
        {modalImage && <img src={modalImage} alt="Dokument" style={{ width: '100%' }} />}
      </Modal>

      {/* Модальное окно для редактирования данных */}
      <Modal
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsEditModalVisible(false)}>
            Anuluj
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Zapisz
          </Button>,
        ]}
      >
        <Form layout="vertical">
          {editingField === 'tax' ? (
            <>
      <Form.Item
        label="Konto bankowe"
        validateStatus={editingData.rachunekBankowy && editingData.rachunekBankowy.length !== 26 ? 'error' : ''}
        help={editingData.rachunekBankowy && editingData.rachunekBankowy.length !== 26 ? 'Numer konta musi zawierać dokładnie 26 cyfr.' : ''}
        rules={[
          {
            required: true,
            message: 'Proszę wprowadzić numer konta bankowego',
          },
          {
            pattern: /^\d{26}$/,
            message: 'Numer konta bankowego musi zawierać dokładnie 26 cyfr.',
          },
        ]}
      >
        <Input
          value={editingData.rachunekBankowy}
          onChange={(e) =>
            setEditingData({ ...editingData, rachunekBankowy: e.target.value })
          }
          maxLength={26} // Ограничение длины ввода
        />
      </Form.Item>
              <Form.Item label="Urząd skarbowy">
                <Input
                  value={editingData.urzadSkarbowy}
                  onChange={(e) =>
                    setEditingData({ ...editingData, urzadSkarbowy: e.target.value })
                  }
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label="Imię">
                <Input
                  value={editingData.firstName}
                  onChange={(e) =>
                    setEditingData({ ...editingData, firstName: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Nazwisko">
                <Input
                  value={editingData.lastName}
                  onChange={(e) =>
                    setEditingData({ ...editingData, lastName: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Telefon">
                <Input
                  value={editingData.phone}
                  onChange={(e) =>
                    setEditingData({ ...editingData, phone: e.target.value })
                  }
                />
              </Form.Item>
              {/* Add more fields as needed */}
            </>
          )}
        </Form>
      </Modal>
            {/* Модальное окно для редактирования okres rozliczeniowy */}
            <Modal
        visible={isPeriodModalVisible}
        onCancel={() => setIsPeriodModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsPeriodModalVisible(false)}>
            Anuluj
          </Button>,
          <Button key="save" type="primary" onClick={handleSavePeriod}>
            Zapisz
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Okres rozliczeniowy">
            <Select
              value={selectedPeriod}
              onChange={(value) => setSelectedPeriod(value)}
              options={[
                { label: 'Tydzień', value: 'tydzień' },
                { label: 'Dwa tygodnie', value: 'dwa tygodnie' },
                { label: 'Miesiąc', value: 'miesiąc' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Konto;
